import Container_ArrowRightRibbonComponent from '@wix/thunderbolt-elements/src/components/Container/viewer/skinComps/ArrowRightRibbon/ArrowRightRibbon.skin';


const Container_ArrowRightRibbon = {
  component: Container_ArrowRightRibbonComponent
};


export const components = {
  ['Container_ArrowRightRibbon']: Container_ArrowRightRibbon
};

